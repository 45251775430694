export default [
    {
        path: '/practices',
        name: 'iab-practices',
        component: () => import('@/views/pages/practices/List.vue'),
        meta: {
            pageTitle: 'Practices',
            breadcrumb: [
                {text: 'Practices',active: true}
            ]
        }
    },
    {
        path: '/faqs',
        name: 'iab-faqs',
        component: () => import('@/views/pages/faqs/List.vue'),
        meta: {
            pageTitle: 'Faqs',
            breadcrumb: [
                {text: 'Faqs',active: true}
            ]
        }
    },
    {
        path: '/practice',
        name: 'iab-practice',
        component: () => import('@/views/pages/practice/PracticeProfile.vue'),
        meta: {
            pageTitle: 'Practice',
            breadcrumb: [
                {text: 'Practice',active: true}
            ]
        }
    },
    {
        path: '/practice/edit',
        name: 'iab-practice-edit',
        component: () => import('@/views/pages/practice/PracticeEdit.vue'),
        meta: {
            pageTitle: 'Edit Practice',
            breadcrumb: [
                {
                    text: 'Practice',
                    to: '/practice',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/practice/:id',
        name: 'iab-other-practice',
        component: () => import('@/views/pages/practice/PracticeProfile.vue'),
        meta: {
            pageTitle: 'Practice',
            breadcrumb: [
                {
                    text: 'Practice',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/owners',
        name: 'iab-owner-list',
        component: () => import('@/views/pages/owner/OwnerList.vue'),
        meta: {
            pageTitle: 'Practice Owners',
            breadcrumb: [
                {
                    text: 'Owners',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/owner/create',
        name: 'iab-owner-create',
        component: () => import('@/views/pages/owner/OwnerEdit.vue'),
        meta: {
            pageTitle: 'Create Practice Owner',
            breadcrumb: [
                {
                    text: 'Owners',
                    to: '/owners',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/owner/:id',
        name: 'iab-owner',
        component: () => import('@/views/pages/owner/OwnerProfile.vue'),
        meta: {
            pageTitle: 'Practice Owner',
            breadcrumb: [
                {
                    text: 'Owners',
                    to: '/owners',
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/owner/:id/edit',
        name: 'iab-owner-edit',
        component: () => import('@/views/pages/owner/OwnerEdit.vue'),
        meta: {
            pageTitle: 'Practice Owner',
            breadcrumb: [
                {
                    text: 'Owners',
                    to: '/owners',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-owners',
        name: 'iab-client-owner-list',
        component: () => import('@/views/pages/client-owner/ClientOwnerList.vue'),
        meta: {
            pageTitle: 'Client Owners',
            breadcrumb: [
                {
                    text: 'Client Owners',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-owner/create',
        name: 'iab-client-owner-create',
        component: () => import('@/views/pages/client-owner/ClientOwnerEdit.vue'),
        meta: {
            pageTitle: 'Create Client Owner',
            breadcrumb: [
                {
                    text: 'Client Owners',
                    to: '/client-owners',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-owner/:id',
        name: 'iab-client-owner',
        component: () => import('@/views/pages/client-owner/ClientOwnerProfile.vue'),
        meta: {
            pageTitle: 'Practice Owner',
            breadcrumb: [
                {
                    text: 'Client Owners',
                    to: '/client-owners',
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-owner/:id/edit',
        name: 'iab-client-owner-edit',
        component: () => import('@/views/pages/client-owner/ClientOwnerEdit.vue'),
        meta: {
            pageTitle: 'Client Owner',
            breadcrumb: [
                {
                    text: 'Client Owners',
                    to: '/client-owners',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-secondary-owners',
        name: 'iab-client-secondary-owner-list',
        component: () => import('@/views/pages/client-secondary-owner/ClientSecondaryOwnerList.vue'),
        meta: {
            pageTitle: 'Secondary Client Owners',
            breadcrumb: [
                {
                    text: 'Secondary Client Owners',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-secondary-owner/create',
        name: 'iab-client-secondary-owner-create',
        component: () => import('@/views/pages/client-secondary-owner/ClientSecondaryOwnerEdit.vue'),
        meta: {
            pageTitle: 'Create Client Secondary Owner',
            breadcrumb: [
                {
                    text: 'Client Secondary Owners',
                    to: '/client-secondary-owners',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-secondary-owner/:id',
        name: 'iab-client-secondary-owner',
        component: () => import('@/views/pages/client-secondary-owner/ClientSecondaryOwnerProfile.vue'),
        meta: {
            pageTitle: 'Practice Secondary Owner',
            breadcrumb: [
                {
                    text: 'Client Secondary Owners',
                    to: '/client-secondary-owners',
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client-secondary-owner/:id/edit',
        name: 'iab-client-secondary-owner-edit',
        component: () => import('@/views/pages/client-secondary-owner/ClientSecondaryOwnerEdit.vue'),
        meta: {
            pageTitle: 'Client Secondary Owner',
            breadcrumb: [
                {
                    text: 'Client Secondary Owners',
                    to: '/client-secondary-owners',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/clients',
        name: 'iab-client-list',
        component: () => import('@/views/pages/client/ClientList.vue'),
        meta: {
            pageTitle: 'Clients',
            breadcrumb: [
                {
                    text: 'Clients',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client/create',
        name: 'iab-client-create',
        component: () => import('@/views/pages/client/ClientEdit.vue'),
        meta: {
            pageTitle: 'Create Client',
            breadcrumb: [
                {
                    text: 'Clients',
                    to: '/clients',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client/:id',
        name: 'iab-client',
        component: () => import('@/views/pages/client/ClientProfile.vue'),
        meta: {
            pageTitle: 'View Client',
            breadcrumb: [
                {
                    text: 'Clients',
                    to: '/clients',
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/client/:id/edit',
        name: 'iab-client-edit',
        component: () => import('@/views/pages/client/ClientEdit.vue'),
        meta: {
            pageTitle: 'Edit Client',
            breadcrumb: [
                {
                    text: 'Clients',
                    to: '/clients',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/staff',
        name: 'iab-staff-list',
        component: () => import('@/views/pages/staff/StaffList.vue'),
        meta: {
            pageTitle: 'Practice Staff',
            breadcrumb: [
                {
                    text: 'Staff',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/staff/create',
        name: 'iab-staff-create',
        component: () => import('@/views/pages/staff/StaffEdit.vue'),
        meta: {
            pageTitle: 'Create Staff',
            breadcrumb: [
                {
                    text: 'Staff',
                    to: '/staff',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/staff/:id',
        name: 'iab-staff',
        component: () => import('@/views/pages/staff/StaffProfile.vue'),
        meta: {
            pageTitle: 'Staff',
            breadcrumb: [
                {
                    text: 'Staff',
                    to: '/staff',
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/staff/:id/edit',
        name: 'iab-staff-edit',
        component: () => import('@/views/pages/staff/StaffEdit.vue'),
        meta: {
            pageTitle: 'Edit Staff',
            breadcrumb: [
                {
                    text: 'Staff',
                    to: '/staff',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/subcontractors',
        name: 'iab-contractor-list',
        component: () => import('@/views/pages/contractor/ContractorList.vue'),
        meta: {
            pageTitle: 'Practice Subcontractors',
            breadcrumb: [
                {
                    text: 'Subcontractors',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/subcontractor/create',
        name: 'iab-contractor-create',
        component: () => import('@/views/pages/contractor/ContractorEdit.vue'),
        meta: {
            pageTitle: 'Subcontractors',
            breadcrumb: [
                {
                    text: 'Subcontractors',
                    to: '/subcontractors',
                },
                {
                    text: 'Create',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/subcontractor/:id',
        name: 'iab-contractor',
        component: () => import('@/views/pages/contractor/ContractorProfile.vue'),
        meta: {
            pageTitle: 'View Subcontractor',
            breadcrumb: [
                {
                    text: 'Subcontractors',
                    to: '/subcontractors',
                },
                {
                    text: 'View',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/subcontractor/:id/edit',
        name: 'iab-contractor-edit',
        component: () => import('@/views/pages/contractor/ContractorEdit.vue'),
        meta: {
            pageTitle: 'Edit Subcontractor',
            breadcrumb: [
                {
                    text: 'Subcontractors',
                    to: '/subcontractors',
                },
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tasks',
        name: 'iab-tasks-list',
        component: () => import('@/views/pages/task/TaskList.vue'),
        meta: {
            pageTitle: 'My Tasks',
            breadcrumb: [
                {
                    text: 'Task List',
                    active: true,
                },
            ],
        },
    },
]