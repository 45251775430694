import ToastificationContent from "@core/components/toastification/ToastificationContent";

export const globalMixin = {
    components: {

    },
    data() {
        return {
            firstName: 'Alex',
            lastName: 'Smith',
            color: 'red'
        };
    },
    methods: {
        showSuccessMessage(message){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Success!`,
                    icon: 'CheckIcon',
                    variant: 'success',
                    text: message,
                },
            })
        },
        showErrorMessage(message){
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Error!`,
                    icon: 'XIcon',
                    variant: 'danger',
                    text: message,
                },
            })
        }
    }
};