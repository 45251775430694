export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/dashboard/practice',
    name: 'dashboard-practice',
    component: () => import('@/views/dashboard/practice/Practice.vue'),
  },
  {
    path: '/dashboard/admin',
    name: 'dashboard-admin',
    component: () => import('@/views/dashboard/admin/AdminDashboard.vue'),
  },
]
